







import { Component } from 'vue-property-decorator'

import { Department, DescriptionOfDepartment } from '../../../../models'
import { isRelated } from '../../../../services'
import { log } from '../../../../support'

import { AbstractModuleUi } from '../../_abstract'

import { DepartmentsModule, DepartmentsModuleVersion } from '../Departments.contracts'

/**
 * Container component for the `DepartmentsModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<DepartmentsModuleUi>({
  name: 'DepartmentsModuleUi',
  components: {
    DepartmentsModuleUiPresentation: () => import(
      /* webpackChunkName: "modules/Departments" */
      './Departments.ui.presentation.vue'
    )
  }
})
export class DepartmentsModuleUi extends AbstractModuleUi<DepartmentsModule> {
  /**
   * Array of the departments to render.
   */
  public departments: Department[] = []

  /**
   * @inheritDoc
   */
  protected versionEnum = DepartmentsModuleVersion

  /**
   * @inheritDoc
   */
  public async fetchRelated (): Promise<void> {
    await this.fetchDepartments()
  }

  /**
   * Determines whether the component should be rendered.
   */
  public get shouldRender (): boolean {
    return this.hasContent && this.hasValidVersion && this.hasDepartments
  }

  /**
   * Fetches the departments from the `RelatedService`.
   */
  private async fetchDepartments (): Promise<void> {
    const unresolvedDepartments = this.data.content.departments

    if (!isRelated(unresolvedDepartments)) {
      return
    }

    try {
      this.departments =
        await this.relatedService.resolve<DescriptionOfDepartment[]>(unresolvedDepartments)
    } catch (error) {
      const message: string = 'DepartmentsModuleUi.fetchDepartments(): Failed to fetch the departments from the [RelatedService]!'
      log([message, error], 'error')
    }
  }

  /**
   * Determines whether the component has successfully fetched the departments from the `RelatedService`.
   */
  private get hasDepartments (): boolean {
    return this.departments.length > 0
  }
}

export default DepartmentsModuleUi
