// Copyright © 2021 Move Closer

import { AsyncComponent } from 'vue'

import { DepartmentsModuleVersion } from '../Departments.contracts'

/**
 * Registry that binds the `DepartmentsModuleVersion` with the applicable Vue component.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const departmentsModuleVersionComponentRegistry: Record<DepartmentsModuleVersion, AsyncComponent> = {
  [DepartmentsModuleVersion.Carousel]: () => import(
    /* webpackChunkName: "modules/Departments" */
    './partials/Carousel/Carousel.vue'
  ),
  [DepartmentsModuleVersion.Tiles]: () => import(
    /* webpackChunkName: "modules/Departments" */
    './partials/Tiles/Tiles.vue'
  )
}
