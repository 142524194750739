






import { Component, Prop } from 'vue-property-decorator'
import { AsyncComponent } from 'vue'

import { Department } from '../../../../models'
import { log } from '../../../../support'

import { AbstractModuleUiPresentation } from '../../_abstract'

import { DepartmentsModuleVersion } from '../Departments.contracts'

import { departmentsModuleVersionComponentRegistry } from './Departments.ui.config'

/**
 * Presentational component for the `DepartmentsModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<DepartmentsModuleUiPresentation>({
  name: 'DepartmentsModuleUiPresentation'
})
export class DepartmentsModuleUiPresentation extends AbstractModuleUiPresentation {
  /**
   * Array of departments to render.
   */
  @Prop({ type: Array, required: true })
  public readonly departments!: Department[]

  /**
   * Version of the module. Determines which Vue component
   * will be used to render the departments.
   *
   * @see DepartmentsModuleVersion
   */
  @Prop({ type: String, required: true })
  private readonly version!: DepartmentsModuleVersion

  /**
   * Vue component that should be used to render the departments.
   */
  public get component (): AsyncComponent | undefined {
    const component = departmentsModuleVersionComponentRegistry[this.version]

    if (typeof component === 'undefined') {
      log(
        `DepartmentsModuleUiPresentation.component(): There's no Vue component associated with the [${this.version}] DepartmentsModuleVersion!`,
        'error'
      )
      return
    }

    return component
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasDepartments && typeof this.component === 'function'
  }

  /**
   * Determines whether the component has been provided with the correct `departments` @Prop.
   */
  private get hasDepartments (): boolean {
    return typeof this.departments !== 'undefined' &&
      Array.isArray(this.departments) &&
      this.departments.length > 0
  }
}

export default DepartmentsModuleUiPresentation
