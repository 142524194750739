
































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { BootstrapButton, BootstrapIcon } from '@movecloser/ui-core'
import { Autoplay, Mousewheel, Navigation, Swiper } from 'swiper'

import { Department } from '../../../../../../models'

import { HorizontalAlignment } from '../../../../../../contracts'
import { TileBehavior, TileIconPlacement, TileProps } from '../../../../../molecules'
import { TranslateResult } from 'vue-i18n'

/**
 * `TileProps`'s interface keys which values are always dynamic.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
type TilePropsDynamicKeys = 'body' | 'heading' | 'iconName' | 'link'

/**
 * Object containing base (constant) properties of the `TileProps` object.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
const STATIC_TILE_PROPS: Omit<TileProps, TilePropsDynamicKeys> = {
  behavior: TileBehavior.Link,
  border: true,
  contentAlignment: HorizontalAlignment.Left,
  headingAlignment: HorizontalAlignment.Left,
  iconPlacement: TileIconPlacement.Left,
  large: false,
  rounded: false,
  shadow: false,
  transparent: true
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<Carousel>({
  name: 'Carousel',
  components: {
    BootstrapButton,
    BootstrapIcon,
    Tile: () => import(
      /* webpackChunkName: "molecules/Tile" */
      '../../../../../molecules/Tile/Tile.vue'
    )
  },
  mounted (): void {
    this.initSwiper()
  }
})
export class Carousel extends Vue {
  /**
   * Array of departments to render.
   */
  @Prop({ type: Array, required: true })
  public readonly departments!: Department[]

  /**
   * Reference to the `.swiper-container` element.
   */
  @Ref('swiperContainer')
  public swiperContainerRef!: HTMLElement

  /**
   * Swiper instance.
   */
  private swiper: Swiper | null = null

  /**
   * Constructs the object that contains the props required by the `<Tile>` molecule.
   *
   * @param department - The Department object that is to be translated into props.
   */
  public constructTileProps (department: Department): TileProps {
    const { icon: iconName, link, name, openingsCount } = department

    return {
      ...STATIC_TILE_PROPS,
      body: this.$tc(
        'modules.components.modules.Departments.openings-count', openingsCount
      ) as string,
      heading: {
        level: 3,
        text: name
      },
      iconName: iconName || 'Person',
      link: { ...link, label: this.$t('_.see-more'), wcagTitle: name }
    }
  }

  public get isAnimating (): boolean {
    if (typeof this.swiper === 'undefined' || !this.swiper) {
      return false
    }

    return this.swiper.autoplay.running
  }

  /**
   * Determines whether the component has been provided with the correct `departments` @Prop.
   */
  public get hasDepartments (): boolean {
    return typeof this.departments !== 'undefined' &&
      Array.isArray(this.departments) &&
      this.departments.length > 0
  }

  /**
   * Determines departments which has at least one open position.
   */
  public get filteredDepartments (): Department[] {
    return this.departments.filter((department) => department.openingsCount > 0)
  }

  /**
   * Label for the "play/pause" button.
   */
  public get playPauseBtnLabel (): TranslateResult {
    return this.$t(`modules.components.modules.Hero.versions.Slider.play-pause-btn.${
      this.isAnimating ? 'pause' : 'play'
    }`)
  }

  public toggleAnimationBehaviour (): void {
    if (typeof this.swiper === 'undefined' || !this.swiper) {
      return
    }

    this.swiper.autoplay.running ? this.swiper.autoplay.stop() : this.swiper.autoplay.start()
  }

  /**
   * Initialises the Swiper.
   */
  private initSwiper (): void {
    if (typeof window === 'undefined' || !(this.swiperContainerRef instanceof HTMLElement)) {
      return
    }

    Swiper.use([Autoplay, Mousewheel, Navigation])

    this.swiper = new Swiper(this.swiperContainerRef, {
      autoplay: true,
      breakpoints: {
        0: {
          autoplay: { delay: 1000 },
          slidesPerGroup: 1,
          slidesPerView: 1.17,
          spaceBetween: 50,
          speed: 1000
        },
        768: {
          autoplay: { delay: 2000 },
          slidesPerGroup: 2,
          slidesPerView: 2.7,
          speed: 1500
        },
        992: {
          autoplay: { delay: 3000 },
          slidesPerGroup: 4,
          slidesPerView: 4.5,
          speed: 2000
        }
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      slidesOffsetAfter: 30,
      slidesOffsetBefore: 30,
      spaceBetween: 30
    })
  }
}

export default Carousel
