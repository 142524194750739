












import { Component, Prop, Vue } from 'vue-property-decorator'

import { Department } from '../../../../../../models'

import { TileBehavior, TileIconPlacement, TileProps } from '../../../../../molecules'

/**
 * `TileProps`'s interface keys which values are always dynamic.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
type TilePropsDynamicKeys = 'body' | 'heading' | 'iconName' | 'link'

/**
 * Object containing base (constant) properties of the `TileProps` object.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
const STATIC_TILE_PROPS: Omit<TileProps, TilePropsDynamicKeys> = {
  behavior: TileBehavior.Link,
  border: false,
  iconPlacement: TileIconPlacement.Top,
  large: false,
  rounded: false,
  shadow: true,
  transparent: false
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<Tiles>({
  name: 'Tiles',
  components: {
    Tile: () => import(
      /* webpackChunkName: "molecules/Tile" */
      '../../../../../molecules/Tile/Tile.vue'
    )
  }
})
export class Tiles extends Vue {
  /**
   * Array of departments to render.
   */
  @Prop({ type: Array, required: true })
  public readonly departments!: Department[]

  /**
   * Constructs the object that contains the props required by the `<Tile>` molecule.
   *
   * @param department - The Department object that is to be translated into props.
   */
  public constructTileProps (department: Department): TileProps {
    const { icon: iconName, link, name, openingsCount } = department

    return {
      ...STATIC_TILE_PROPS,
      body: this.$tc(
        'modules.components.modules.Departments.openings-count', openingsCount
      ),
      heading: {
        level: 2,
        text: name
      },
      iconName: iconName || 'Person',
      link: { ...link, label: this.$t('_.learn-more'), wcagTitle: `${name}` }
    }
  }

  /**
   * Determines whether the component has been provided with the correct `departments` @Prop.
   */
  public get hasDepartments (): boolean {
    return typeof this.departments !== 'undefined' &&
      Array.isArray(this.departments) &&
      this.departments.length > 0
  }
}

export default Tiles
